<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <TopicsList />
      </div>
    </div>
  </div>
</template>
<script>
import TopicsList from './components/TopicsList.vue';
export default {
  name: 'Topic',
  components: { TopicsList },
};
</script>
