<template>
  <!-- Region -->
  <div v-if="isRegional" class="my-3">
    <Accordion :multiple="false" @tab-open="resetNewRegion()">
      <AccordionTab
        v-for="segmentationItem in segmentationItems"
        :key="segmentationItem.segmentationItemId"
        :header="segmentationItem.name"
      >
        <template v-if="!segmentationItem.parentId">
          <div class="d-flex mb-3">
            <InputText
              placeholder="Parent Region"
              type="text"
              :value="segmentationItem.name"
            />

            <Button
              class="p-button-raised p-button-rounded p-button-primary ms-3"
              :icon="isAddingNewRegion ? 'pi pi-minus' : 'pi pi-plus'"
              @click="addItemToSementationItems()"
            />
            <Button
              class="p-button-raised p-button-rounded p-button-secondary ms-3"
              icon="pi pi-pencil"
              @click="editSegmentationItem(segmentationItem.segmentationItemId)"
            />
            <Button
              label=""
              class="p-button-raised p-button-rounded p-button-danger ms-3"
              icon="pi pi-times"
              @click="
                deleteSegmentationItem(segmentationItem.segmentationItemId)
              "
            />
          </div>

          <div
            v-for="childItem in segmentationItem.inverseParent"
            :key="childItem.segmentationItemId"
            class="d-flex mb-3 ms-5"
          >
            <InputText
              placeholder="Child Region"
              type="text"
              v-model="childItem.name"
              :disabled="true"
            />
            <Button
              class="p-button-raised p-button-rounded p-button-secondary ms-3"
              icon="pi pi-pencil"
              @click="editSegmentationItem(childItem)"
            />
            <Button
              label=""
              class="p-button-raised p-button-rounded p-button-danger ms-3"
              icon="pi pi-times"
              @click="
                deleteSegmentationItem(segmentationItem.segmentationItemId)
              "
            />
          </div>

          <div class="d-flex mb-3 ms-5" v-if="isAddingNewRegion">
            <InputText
              v-model="newRegion"
              placeholder="New Region"
              type="text"
            />
            <Button
              class="p-button-raised p-button-rounded p-button-primary ms-3"
              icon="pi pi-check"
              @click="
                confirmNewSegmentationItem(segmentationItem.segmentationItemId)
              "
            />
          </div>
        </template>
      </AccordionTab>
    </Accordion>
  </div>
 <!-- SegmentationItems -->
  <div v-else>
    <div
      v-for="segmentationItem in segmentationItems"
      :key="segmentationItem.segmentationItemId"
    >
      <div class="my-3 d-flex">
        <InputText 
          type="text" 
          v-model="segmentationItem.name" 
          style="
            width: 25%;
            margin-right: 1rem;
          "
        />
        
        <InputText 
          type="text"  
          v-model="segmentationItem.description" 
        />

        <label style="display: flex; align-items: center; justify-content: center; width: 15rem;"> Order: {{ segmentationItem.order }} </label>
        <!-- Segmentation Item Order up  -->
        <Button
          :disabled="segmentationItem.order == 0"
          icon="pi pi-arrow-up"
          class="p-button-rounded p-button-info mx-2"
          @click="changeSegmentationItemOrder(segmentationItem, true)"
        />
        <!-- Segmentation Item Order down  -->
        <Button
          :disabled="segmentationItem.order == segmentationItems.length - 1"
          icon="pi pi-arrow-down"
          class="p-button-rounded p-button-danger"
          @click="changeSegmentationItemOrder(segmentationItem, false)"
        />
        <!-- Segmentation Item Save  -->
        <Button
          class="p-button-raised p-button-rounded p-button-secondary ms-3"
          icon="pi pi-save"
          @click="editSegmentationItem(segmentationItem)"
        />
        <!-- Segmentation Item delete  -->
        <Button
          label=""
          class="p-button-raised p-button-rounded p-button-danger ms-3"
          icon="pi pi-trash"
          @click="deleteSegmentationItem(segmentationItem.segmentationItemId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'SegmentationItemsList',
  props: ['segmentationItems', 'isRegional'],
  emits:['changeSegmentationItemOrderEmits'],
  data() {
    return {
      newRegion: null,
      isAddingNewRegion: false,
    };
  },
  components: {
    Button,
    InputText,
    Accordion,
    AccordionTab,
  },
  methods: {
    async editSegmentationItem(segmentationItem) {
      try {
        await this.$store.dispatch(
          'modifySegmentationItem',
          segmentationItem
        );
        toast.success('Successfully Updated.');
      } catch (error) {
        toast.error(error.message);
      }
    },
    async deleteSegmentationItem(element) {
      try {
        const selectedSegmentationItem = this.segmentationItems.find(
          (si) => si.segmentationItemId == element
        );
        const segmentationItemId = selectedSegmentationItem.segmentationItemId;
        const segmentationId = selectedSegmentationItem.segmentationId;
        await this.$store.dispatch('removeSegmentationItem', {
          segmentationItemId,
          segmentationId,
        });
        toast.success('Successfully deleted.');
      } catch (error) {
        toast.error(error.message);
      }
    },
    addItemToSementationItems() {
      this.isAddingNewRegion = !this.isAddingNewRegion;
    },
    confirmNewSegmentationItem(parentId) {
      console.log(this.newRegion);
      console.log(parentId);
    },
    resetNewRegion() {
      this.newRegion = null;
      this.isAddingNewRegion = false;
    },
    changeSegmentationItemOrder(segmentationItem, goUp){
      this.$emit("changeSegmentationItemOrderEmits",segmentationItem, goUp)
    }
  },
};
</script>
