<template>
  <!-- Edit Topic -->
  <Dialog
    v-model:visible="showDialog"
    
    header="Edit Topic"
    :modal="true"
    class="p-fluid dialogModal"
    @hide="closeDialog()"
  >
  <!-- name -->
    <div class="field" style="margin: 20px; margin-bottom: 1rem;">
      <label for="name">Name</label>
      <InputText
        id="name"
        v-model.trim="form.name"
        @blur="v$.form.name.$touch"
        required="true"
        autofocus
        :class="{ 'p-invalid': !form.name }"
      />
      <small class="p-error" v-if="!form.name">Name is required.</small>
    </div>
  <!-- notes -->
    <!-- <div class="field my-3">
      <Panel header="Notes" :toggleable="true" :collapsed="true">
        <QuillEditor
          theme="snow"
          toolbar="full"
          v-model:content="form.notes"
          @blur="v$.form.notes.$touch"
          contentType="html"
          :style="{ 'min-height': '500px' }"
        />
      </Panel>
    </div> -->
    <!-- segmentations -->
    <Divider align="left">
      <div class="inline-flex align-items-center">
        <span class="p-tag">Segmentations</span>
      </div>
    </Divider>

    <!-- <SegmentationsRegionList :topicId="selectedElement.topicId" /> -->
    <!-- <SegmentationsList :topicId="selectedElement.topicId" /> -->
    <SegmentationTestListVue 
      :topicId="selectedElement.topicId" 
    />
    <!-- Scenarios -->
    <Divider align="left">
      <div class="inline-flex align-items-center">
        <span class="p-tag">Scenarios</span>
      </div>
    </Divider>
    <ScenariosList :topicId="selectedElement.topicId" />

    <!-- footer  -->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :loading="editIsLoading"
        :disabled="this.v$.form.$invalid || editIsLoading"
        @click="save()"
      />
    </template>
  </Dialog>
</template>

<script>
// import SegmentationsList from './SegmentationsList.vue';
// import SegmentationsRegionList from './SegmentationsRegionList.vue';
import SegmentationTestListVue from './SegmentationTestList.vue';
import ScenariosList from './ScenariosList.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
// import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
// import { QuillEditor } from '@vueup/vue-quill';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'EditTopic',
  components: {
    Button,
    InputText,
    // QuillEditor,
    Dialog,
    // SegmentationsList,
    // SegmentationsRegionList,
    SegmentationTestListVue,
    ScenariosList,
    // Panel,
    Divider,
  },
  props: ['selectedElement'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      editIsLoading: false,
      showDialog: false,
      form: {
        name: null,
        // notes: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        // notes: { required },
      },
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.form = {
        name: null,
        // notes: null,
      };
      this.$emit('closeDialog', 'edit');
    },
    async save() {
      try {
        if (!this.v$.form.$invalid) {
          this.editIsLoading = true;
          const topic = {
            ...this.selectedElement,
            name: this.form.name,
            // notes: this.form.notes,
          };
          await this.$store.dispatch('modifyTopic', topic);
          this.editIsLoading = false;
          toast.success('Successfully updated.');
          this.closeDialog();
        } else {
          toast.error('Wrong topic data.');
        }
      } catch (error) {
        this.editIsLoading = false;
        toast.error(error.message);
      }
    },
  },
  watch: {
    selectedElement(newVal) {
      if (newVal) {
        this.showDialog = true;
        this.form = Object.assign({}, this.selectedElement);
      }
    },
  },
};
</script>

<style>
.dialogModal{
    width: 100%;
  }
@media (  min-width:1024px ) {
  .dialogModal{
    width: 70%;
  }
}
@media (  max-width:1024px ) {
  .dialogModal{
    width: 90%;
  }
}

</style>
