<template>
  <div class="card">
    <DataTable
      :value="topics"
      :loading="loading"
      responsiveLayout="scroll"
      showGridlines
      :paginator="true"

      :rows="rowsPagValue"
      :rowsPerPageOptions="[10, 20, 50]"
      :rowHover="true"
      :first="firstPagValue"

      removableSort
      dataKey="id"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      v-model:filters="filters"
      filterDisplay="menu"
      :sort-field="sortFieldValue"
      :sort-order="sortOrderValue"
      @sort="onSort($event)"
      @page="onSort($event)"
    >
      <template #header>
        <h5 class="mb-2 md:m-0 p-as-md-center">Topics</h5>
        <div
          class="table-header row gy-3 d-flex flex-row justify-content-between"
        >
          <div
            class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start"
          >
            <CreateTopic />
          </div>

          <div class="col-sm-12 col-md-8">
            <div class="d-flex flex-row justify-content-md-end">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Clear"
                class="p-button-outlined me-4"
                @click="clearFilter()"
              />
              <span class="p-input-icon-left" style="position: relative;">
                <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Keyword Search"
                  class="keywordSearch"
                />
              </span>
            </div>
          </div>
        </div>
      </template>
      <!-- title check the data of columns using slotprops mb not necessary field -->
      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
      > 
      <template #body="slotProps">
          <div
            @click="openEditDialog(slotProps.data)"
            :style="{'width': '100%', 'height': '100%', 'cursor': 'pointer'}"
          >
            {{ slotProps.data.name }}
          </div>
      </template>
      </Column>

      <Column :exportable="false" style="width: 10rem;">
        <template #body="slotProps" >
          <!-- feat edit pencil not necessary  -->
          <!-- <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mx-2"
            @click="openEditDialog(slotProps.data)"
          /> -->
          
          <div style="display: grid; place-items: center;">
            <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="openDeleteDialog(slotProps.data)"
            />
          </div>
        </template>
      </Column>

      <template #paginatorstart>
        <Button
          type="button"
          icon="pi pi-refresh"
          class="p-button-text"
          @click="getTopics()"
        />
      </template>
    </DataTable>
  </div>

  <DeleteTopic
    :selectedElement="selectedDeleteRow"
    @closeDialog="closeDialog($event)"
  />

  <EditTopic
    :selectedElement="selectedEditRow"
    @closeDialog="closeDialog($event)"
  />
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import CreateTopic from './CreateTopic.vue';
import EditTopic from './EditTopic.vue';
import DeleteTopic from './DeleteTopic.vue';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'TopicsList',
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    CreateTopic,
    EditTopic,
    DeleteTopic,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      deleteTopicIsLoading: false,
      filters: null,
      createDialog: false,
      selectedEditRow: null,
      selectedDeleteRow: null,
      //Sort
      sortFieldValue: null, 
      sortOrderValue: null,
      //paginationCountValues
      firstPagValue: null,
      rowsPagValue: 50,
    };
  },
  created() {
    this.columns = [{ field: 'name', header: 'Name' }];
    this.initFilters();
  },

  async mounted() {
    await this.getTopics();
    this.setAdminTopicSort();
  },

  computed: {
    topics() {
      return this.$store.getters.obtainTopics;
    },
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    adminTopicSort(){
      return this.$store.getters.obtainAdminTopicSort;
    }
  },
  methods: {
    async getTopics() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainTopics');
        toast.success('Topics obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    setAdminTopicSort(){
      try {
        this.sortFieldValue = this.adminTopicSort.sortField;
        this.sortOrderValue = this.adminTopicSort.sortOrder;

        if(this.adminTopicSort.firstPagValue === null){
          this.firstPagValue = 0;
        }
        else{
          this.firstPagValue = this.adminTopicSort.firstPagValue;
        }
        if(this.adminTopicSort.rowsPagValue === null){
          this.rowsPagValue = 50;
        }
        else{
          this.rowsPagValue = this.adminTopicSort.rowsPagValue
        }

      } catch (error) {
        console.log(error);
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    openEditDialog(element) {
      this.selectedEditRow = element;
    },
    openDeleteDialog(element) {
      this.selectedDeleteRow = element;
    },
    closeDialog() {
      this.selectedEditRow = null;
      this.selectedDeleteRow = null;
      this.createDialog = false;
    },

    //Sort functions
    onSort(e){
      const adminTopicSort = {
        sortField: e.sortField,
        sortOrder : e.sortOrder,
        firstPagValue: e.first,
        rowsPagValue: e.rows
      }
      this.$store.dispatch('updateAdminTopicSort', adminTopicSort)
    }
  },
};
</script>

<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>