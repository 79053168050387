<template>
    <div class="card my-3" style="margin: 20px;">
        <DataTable 
            :value="scenarios" 
            :loading="loading" 
            responsiveLayout="scroll" 
            showGridlines 
            :rowHover="true" 
            removableSort 
            dataKey="id"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            v-model:filters="filters"
            filterDisplay="menu"
        >
            <!-- selectionMode="single" 
            @rowSelect="onRowSelect" -->

            <template #header>
                <h5 class="mb-2 md:m-0 p-as-md-center"> Scenarios</h5>
                <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                    <!-- <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                        <Button type="button" icon="pi pi-plus" label="Add" class="p-button-outlined"
                            @click="openCreateScenarioDialog()" />
                    </div> -->

                    <!-- <div class="col-sm-12 col-md-8"> -->
                        <!-- <div class="d-flex flex-row justify-content-md-end"> -->
                            <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined me-4"
                                @click="clearFilter()" /> -->
                            <!-- <span class="p-input-icon-left"> -->
                                <!-- <i class="pi pi-search" /> -->
                                <!-- <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> -->
                            <!-- </span> -->
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </template>
            <Column 
                v-for="col of columns" 
                :field="col.field" 
                :header="col.header" 
                :key="col.field" 
                :sortable="true">
                <template #body="slotProps">
                    <div @click="openEditDialog(slotProps.data)"
                        :style="{'width': '100%', 'height': '100%', 'cursor': 'pointer'}">
                        {{ slotProps.data.name }}
                    </div>
                </template>
            </Column>
            <Column :exportable="false" style="width: 10rem;">
                <template #body="slotProps">
                    <div style="display: grid; place-items: center;">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            @click="openDeleteDialog(slotProps.data)" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <div style="width: 25%; margin: 20px;">
        <Button 
            type="button" 
            icon="pi pi-plus" 
            label="Add Scenarios" 
            class="p-button-outlined"
            @click="openCreateScenarioDialog()" 
        />
    </div>
    <!-- create scenario -->
    <Dialog v-model:visible="createScenarioDialog" :style="{ width: '90%' }" header="Create Scenario" :modal="true"
        class="p-fluid" :maximizable="true">
        <div class="field">
            <label for="name">Name</label>
            <InputText id="name" v-model.trim="newScenarioForm.name" @blur="v$.newScenarioForm.name.$touch"
                required="true" autofocus :class="{ 'p-invalid': !newScenarioForm.name }" />
            <small class="p-error" v-if="!newScenarioForm.name">
                Name is required.
            </small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog()" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" :loading="createScenarioIsLoading" :disabled="
              this.v$.newScenarioForm.$invalid || createScenarioIsLoading
            " @click="createScenario()" />

        </template>
    </Dialog>
    <!-- delete scenario -->
    <Dialog v-model:visible="deleteScenarioDialog" :style="{ width: '450px' }" header="Delete Scenario" :modal="true">
        <div class="confirmation-content d-flex flex-row">
            <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
            <span>
                Are you sure you want to delete
                <span class="fw-bold">{{ selectedRowScenario.name }}</span>
            </span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeDialog()" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="deleteIsLoading"
                :disabled="deleteIsLoading" @click="deleteElement()" />
        </template>
    </Dialog>
    <!-- edit category -->
    <Dialog 
        v-model:visible="editScenarioDialog" 
        :style="{ width: '90%' }" 
        header="Edit Scenario" 
        :modal="true"
        class="p-fluid"
        
        >
        <div class="field">
            <label for="name">Name</label>
            <InputText 
                id="name"
                v-model.trim="editScenarioForm.name"
                @blur="v$.editScenarioForm.name.$touch"
                required="true" 
                autofocus
                :class="{ 'p-invalid': !editScenarioForm.name }" 
            />
            <small class="p-error" v-if="!editScenarioForm.name">Name is required.</small>
        </div>

        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" :loading="editScenarioIsLoading"
                :disabled="this.v$.editScenarioForm.$invalid || editScenarioIsLoading" @click="editElement()" />
        </template>

    </Dialog>

</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

// import { useToast } from 'vue-toastification';

// const toast = useToast();

export default {
    name: 'ScenariosList',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Dialog,
    },
    props: ['topicId'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            deleteIsLoading: false,
            createScenarioIsLoading: false,
            editScenarioIsLoading: false,
            deleteScenarioIsLoading: false,
            selectedScenarioId: null,
            filters: null,
            createScenarioDialog: false,
            editScenarioDialog: false,
            deleteScenarioDialog: false,
            scenarioDetailDialog: false,
            selectedRowScenario: null,
            newScenarioForm: {
                name: null,
            },
            editScenarioForm: {
                name:null,
                scenarioId:null,
                topicId:null,
            },
            scenarios: [],
        };
    },
    validations() {
        return {
            newScenarioForm: {
                name: { required },
            },
            editScenarioForm: {
                name: { required }
            },
        };
    },
    created() {
        this.columns = [{ fields: 'name', header: 'Name' }];
        this.initFilters();
    },
    computed: {
        // scenarios() {
        //     return this.$store.getters.obtainScenarios;
        // },
        // scenarios for now is a vue varialble 
    },
    async mounted() {
        await this.getScenarios(this.topicId);
    },
    methods: {
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        openCreateScenarioDialog() {
            this.createScenarioDialog = true;
        },
        openEditDialog(data) {
            this.editScenarioDialog = true; 
            // this.selectedRowScenario = data;
            
            let d1 = new Object(data)
            this.editScenarioForm.name =  d1.name ;
            this.editScenarioForm.scenarioId =  d1.scenarioId ;
            this.editScenarioForm.topicId = d1.topicId ;
        },
        openDeleteDialog(data) {
            this.deleteScenarioDialog = true;
            this.selectedRowScenario = data
        },
        async getScenarios(topicId) {
            try {
                this.loading = true
                await this.$store.dispatch('obtainScenariosByTopicId', topicId)
                this.scenarios = this.$store.getters.obtainScenarios
                this.loading = false
            } catch (error) {
                this.loading = true;
                this.loading = false;
            }
        },
        clearFilter() {
            console.log('clearFilter')
        },
        closeDialog() {
            this.deleteScenarioDialog = false;
            this.editScenarioDialog = false;
            this.createScenarioDialog = false;
            this.newScenarioForm.name = null;
            // this.editScenarioForm= null;
        },
        async createScenario() {
            this.createScenarioIsLoading = true
            this.newScenarioForm.topicId = this.topicId
            await this.$store.dispatch('addScenario', this.newScenarioForm)
            this.scenarios = this.$store.getters.obtainScenarios
            this.createScenarioIsLoading = false
            this.closeDialog();
        },
        async deleteElement() {
            this.deleteIsLoading = true;
            await this.$store.dispatch('removeScenario', this.selectedRowScenario.scenarioId)
            this.scenarios = this.$store.getters.obtainScenarios
            this.deleteIsLoading = false;
            this.closeDialog();
        },
        async editElement(){
            this.editScenarioIsLoading = true;
            await this.$store.dispatch('modifyScenario' , this.editScenarioForm)
            this.scenarios = this.$store.getters.obtainScenarios
            this.editScenarioIsLoading = false;
            this.closeDialog();
        }
    }
}

</script>