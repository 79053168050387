<template>
    <div style=" 
            margin-top: 1rem;
            width:100%;
            display:flex;
            flex-wrap: wrap; 
    ">
        <!-- {{repeatedCountry}} -->
        <!-- {{selectedRowSegmentation}} -->
        <!-- {{segmentationItem}} -->
        <!-- {{regionCountries}} -->
        <!-- {{index}} -->
        <!-- {{c}} -->
        <!-- {{cList}} -->
        <!-- <div v-for="country in countries[index] " :key="country.regionId" style="margin-right: 0.5rem;"> -->
        <div 
            v-for="country in c" 
            :key="country.regionId" 
            style="margin-right: 0.5rem;">
            <div 
                v-if="repeatedCountry.some(element => element === country.country.name)" 
                style="
                    background-color: firebrick;
                    color: white;
                    margin:2px;
                    padding: 9px 8px;
                    border-radius: 15px;">
                <!-- {{country.name}}  -->
                <!-- {{country}}  -->
                <!-- {{segmentationItem.region.regionId}} -->
                {{country.country.name}}
                |
                <span style="
                        color: white;
                        font-weight: bold;
                        cursor: pointer;"
                    @click="removeChip(country.country.countryId , segmentationItem.region.regionId  )">
                    x
                </span>
            </div>
            <div v-else style="
                    background-color: lightgray;
                    margin:2px;
                    padding: 9px 8px;
                    border-radius: 15px;">
                <!-- {{country.name}} {{country.countryId}} {{segmentationItem.region.regionId}} -->
                {{country.country.name}}
                |
                <span style="
                        color: purple;
                        font-weight: bold;
                        cursor: pointer;"
                    @click="removeChip(country.countryId , segmentationItem.region.regionId  )">
                    x
                </span>
            </div>
        </div>
        <!-- for add chip  -->
        <div style="background-color: lightgray; margin:2px; padding: 9px 8px; border-radius: 15px;  display: flex;">
            <Dropdown :options="regionCountries" v-model="newChip" optionLabel="name" optionValue="countryId"
                :filter="true" class="p-dropdown-chip-country" :showClear="true">
            </Dropdown>
            |
            <span style="margin-left: 0.5rem; color: purple; font-weight: bold; cursor: pointer;"
                @click="addChip(newChip , segmentationItem.region.regionId, index)">
                Add
            </span>
        </div>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { useToast } from 'vue-toastification';
import { createRegionCountries, deleteRegionCountries } from '../../region/services/region.service.js'

const toast = useToast();

export default {
    name: 'Chips',
    components: {
        Dropdown,
    },
    props: ['repeatedCountry', 'selectedRowSegmentation', 'segmentationItem', 'index', 'regionCountries'],
    //   setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            newChip: null, // add chip
            c: [],
            cList: [],
            // segmentationItem, segmentationID
            sI: null,
            S: null,
        };
    },
    created() {
        this.sI = this.segmentationItem.segmentationItemId
        this.s = this.segmentationItem.segmentationId
    },
    computed: {
    },
    async mounted() {
        this.addToCountries();
    },
    methods: {
        async removeChip(countryId, regionId, ) { // remove chip from countries
            const regionCountryForm = {
                "regionId": regionId,
                "countryId": countryId
            }
            await deleteRegionCountries(regionCountryForm);
            // this.c = [...this.c.filter((c1) => c1.countryId !== regionCountryForm.countryId)]
            await this.$store.dispatch('deleteRegionCountries', {regionCountries :regionCountryForm.countryId , segmentationId: this.s , segmentationItemId: this.sI});
            await this.addToCountries()
            this.repeatedCountryFunction();
        },
        async addChip(countryId, regionId, ) {
            // console.log(countryId, regionId, index)
            const regionCountryForm = {
                "regionId": regionId,
                "countryId": countryId
            }
            
            const a = await createRegionCountries(regionCountryForm); // createRegionCountries Service 
            if (a.data) {
                console.log(a.data);
                const b = this.regionCountries.filter(rc => rc.countryId === a.data.countryId)
                const regionCountryForm = {
                    "regionCountryId":a.data.regionCountryId,
                    "regionId": a.data.regionId,
                    "countryId": a.data.countryId,
                    "segmentationItemId":null,
                    "country": b[0]
                }
                // await this.$store.dispatch('createRegionCountries', {regionCountries :b[0] , segmentationId: this.s , segmentationItemId: this.sI});
                await this.$store.dispatch('createRegionCountries', {regionCountries :regionCountryForm , segmentationId: this.s , segmentationItemId: this.sI});
                this.repeatedCountryFunction();
            }
            else {
                toast.error(a.message);
            }
            // this.$emit('addChip', { index: index, a: a }) // emit 
            this.newChip = null;
        },

        async addToCountries() { // push to countryArray when initialize 
            // this.c = this.segmentationItem.region.countries
            this.c = this.segmentationItem.region.regionCountries
            this.repeatedCountryFunction()
        },
        repeatedCountryFunction() {
            let a = []
            this.selectedRowSegmentation.map(se => se.region.regionCountries.map(rc=> a.push(rc.country)))
            // this.selectedRowSegmentation.map(se => a.push(se.region.regionCountries))
            // this.selectedRowSegmentation.map(se =>se.region.map(regionCountry => a.push(regionCountry.country)) )
            this.cList = a.flat();
            if (this.cList.length > 0) {
                const valueArr = this.cList.map(item => item.name)
                const isDuplicate = valueArr.filter((item, index) => valueArr.indexOf(item) != index)
                // this.repeatedCountry = isDuplicate
                // console.log(isDuplicate)
                this.$emit('isRepeatCountry', isDuplicate) // emit 
            }
            else {
                // console.log('no countries List or ');
            }
        }
    },
    watch: {
    },
};
</script>
<style scoped>

.p-dropdown-chip-country{
    width: 11rem !important;
}
</style>